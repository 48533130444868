import { defineStore } from 'pinia'
// import dayjs from 'dayjs'

export const useFiltersRipsStore = defineStore({
  id: 'filtersRips',
  state: () => ({
    _filters: {
      fechaInicial: '',
      fechaFinal: '',
      modalidad: '',
      numFacturas: [],
      clientes: []
    },
    _refrescarListadoRips: false,
    _limpiarListadoRips: false,
    _config: {
      dateRips: ''
    }
  }),
  getters: {
    getFilters: (state) => state._filters,
    getConfig: (state) => state._config,
    getRefrescarListadoRips: (state) => state._refrescarListadoRips,
    getLimpiarListadoRips: (state) => state._limpiarListadoRips
  },
  actions: {
    setUpdateFilters (filters) {
      this._filters = filters
    },
    setConfig (config) {
      this._config = config
    },
    setRefrescarListadoRips (payload) {
      this._refrescarListadoRips = payload
    },
    setLimpiarListadoRips (payload) {
      console.log('payload', payload)
      this._limpiarListadoRips = payload
    }
  }
})
