import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_RIPS

export default class ClientesRipsService {
  listar () {
    return http.get(`${baseUrl}/clientes/listar`, {
      headers: {
        loading: true
      }
    })
  }
}
