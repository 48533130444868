import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_RIPS

export default class FacturasRipsService {
  listarFacturasRips (params) {
    return http.post(`${baseUrl}/facturas/listar`, params, {
      headers: {
        loading: true
      }
    })
  }

  generarJsonRips (params) {
    return http.post(`${baseUrl}/generar/json`, params, {
      headers: {
        loading: true
      },
      responseType: 'blob'
    })
  }

  getConfigRips () {
    return http.get(`${baseUrl}/generar/config`, {
      headers: {
        loading: true
      }
    })
  }
}
