<script setup>
import { onMounted, ref, reactive, watch, computed } from 'vue'
import ClientesRipsService from '@/apps/pharmasan/ventas/rips/services/clientes.service'
import { useFiltersRipsStore } from '../../../stores/filtersRips.store'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'

const _ClientesRipsService = ref(new ClientesRipsService())
const filtersRipsStore = useFiltersRipsStore()
const facturasSeleccionadas = ref('')
const listClientes = ref([])
const listModalidad = ref([
  { id: '01', name: 'CAPITACION' },
  { id: '02', name: 'EVENTO' }
])
const filters = reactive({
  fechaInicial: '',
  fechaFinal: '',
  modalidad: '',
  numFacturas: [],
  clientes: []
})

// Computada con la config del store
const config = computed(() => {
  return filtersRipsStore.getConfig
})

/**
 * Función para validar la entrada de teclado en el campo de facturas.
 * Evita la acción predeterminada al presionar Enter para prevenir saltos de línea.
 * Permite las teclas funcionales específicas definidas en functionalKeys.
 * Evita la entrada de caracteres no numéricos, espacios o comas.
 * @param {KeyboardEvent} e - Evento de teclado.
 */
const validarFacturas = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault()
    guardarFacturas()
  }

  const functionalKeys = [8, 9, 13, 16, 17, 18, 20, 35, 36, 37, 38, 39, 40, 46, 67, 86]
  if (functionalKeys.includes(e.keyCode)) {
    return
  }

  if (/[^0-9\s,]/.test(e.key)) {
    e.preventDefault()
  }
}

/**
 * Función para validar el contenido al pegar texto.
 * Obtiene los datos del portapapeles y verifica si contiene caracteres no permitidos.
 * Evita el pegado si se encuentra algún carácter no permitido.
 * @param {Event} e - Evento de pegado.
 */
const validarPegado = (e) => {
  const clipboardData = e.clipboardData.getData('text')
  console.log('clipboardData', clipboardData)
  if (/[^0-9\s,]/.test(clipboardData)) {
    e.preventDefault()
  }
}

/**
 * Función para procesar y guardar las facturas seleccionadas.
 * Divide la cadena de facturas por comas y espacios, filtra duplicados y valores vacíos,
 * y los agrega al array de facturas en el filtro.
 * Finalmente, reinicia la cadena de facturas seleccionadas.
 */
const guardarFacturas = () => {
  if (!facturasSeleccionadas.value) {
    return
  }

  for (const factura of facturasSeleccionadas.value.split(/[,\s]+/)) {
    if (!filters.numFacturas.some((f) => f === factura) && factura !== '') {
      filters.numFacturas.push(factura)
    }
  }

  facturasSeleccionadas.value = ''
}

/**
 * Función para eliminar una factura del array de facturas en el filtro.
 * @param {string} factura - Número de factura a eliminar.
 */
const eliminarFactura = (factura) => {
  const index = filters.numFacturas.findIndex((f) => f === factura)
  filters.numFacturas.splice(index, 1)
}

/**
 * Función para obtener la lista de clientes.
 */
const fetchClientes = async () => {
  await _ClientesRipsService.value.listar().then((res) => {
    listClientes.value = res.data
  }).catch((err) => {
    console.log('Error ->', err)
  })
}

/**
 * Función para validar la fecha final.
 * Verifica si la fecha final es menor a la fecha inicial.
 * Si es así, muestra un mensaje de error y retorna true.
 * @param {string} fechaInicial - Fecha inicial.
 * @param {string} fechaFinal - Fecha final.
 * @returns {boolean} - Retorna true si la fecha final es menor a la fecha inicial.
 */
const validateFechaFinal = (fechaInicial, fechaFinal) => {
  const fecIni = dayjs(fechaInicial)
  const fecFin = dayjs(fechaFinal)
  if (fecFin.isBefore(fecIni)) {
    Swal.fire({
      icon: 'error',
      title: 'Validación',
      text: 'La fecha final no puede ser menor a la fecha inicial'
    })
    return true
  } else {
    return false
  }
}

// Realizar la búsqueda y setear los filtros en el store
const updateFilter = () => {
  if (validateFechaFinal(filters.fechaInicial, filters.fechaFinal)) {
    return
  }

  filtersRipsStore.setRefrescarListadoRips(true)

  /* const filtersData = {
    fechaInicial: filters.fechaInicial ?? '',
    fechaFinal: filters.fechaFinal ?? '',
    modalidad: filters.modalidad ?? '',
    numFacturas: filters.numFacturas ?? [],
    clientes: filters.clientes ?? []
  }

  filtersRipsStore.setUpdateFilters(filtersData) */
}

/**
 * Función para limpiar los filtros.
 * Reinicia los valores de los filtros a su estado inicial.
 */
const resetClear = () => {
  // Limpiar los filtros
  for (const [key] of Object.entries(filtersRipsStore.getFilters)) {
    if (key === 'numFacturas' || key === 'clientes') {
      filters[key] = []
    } else {
      filters[key] = ''
    }
  }

  filtersRipsStore.setUpdateFilters(filters)
  filtersRipsStore.setLimpiarListadoRips(true)
}

const reload = () => {
  filtersRipsStore.setRefrescarListadoRips(true)
}

// Watch para los cambios en los filtros
watch(filters, (newValue, oldValue) => {
  filtersRipsStore.setUpdateFilters(newValue)
})

// hook onMounted
onMounted(async () => {
  console.log('OnMounted filtros RIPS...')

  await fetchClientes()

  // Setear los filtros del store al filters reactive
  for (const [key] of Object.entries(filtersRipsStore.getFilters)) {
    if (key === 'numFacturas' || key === 'clientes') {
      filters[key] = []
    } else {
      filters[key] = ''
    }
  }
})
</script>

<template>
  <div class="grid grid-cols-12 gap-x-4 gap-y-1">

    <!-- Numero de facturas -->
    <div class="col-span-12 sm:col-span-12">
      <label for="numFacturas" class="block text-xs font-medium text-gray-700 mb-2">Número de Facturas:</label>
      <InputText
        id="numFacturas"
        class="w-full text-xs"
        @keydown="validarFacturas"
        @blur="guardarFacturas"
        @paste="validarPegado"
        v-model="facturasSeleccionadas"
        placeholder="Ingrese número de facturas... (separados por coma o por espacio)"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px; width: 100%'
          }),
          input: { style: 'font-size: 0.75rem' }
        }"
      />
      <div class="w-full flex flex-wrap gap-1 mt-2 max-h-20 overflow-y-auto">
        <div
          v-for="(factura, i) in filters.numFacturas"
          :key="i"
        >
          <div class="bg-blue-200 p-1 flex items-center w-full rounded-md text-blue-800 text-xs">
            <button @click="eliminarFactura(factura)" class="text-blue-800 mr-2">
              <i style="font-size: 0.6rem" class="pi pi-times"></i>
            </button>
            <p>{{ factura }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Clientes -->
    <div class="col-span-12 sm:col-span-6">
      <label for="clientes" class="block text-xs font-medium text-gray-700 mb-2">Clientes:</label>
      <MultiSelect
        id="clientes"
        v-model="filters.clientes"
        :options="listClientes"
        optionValue="CardCode"
        optionLabel="CardName"
        placeholder="Todos los clientes"
        filter
        class="w-full text-sm"
        display="chip"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;'
          }),
          label: { style: 'font-size: 0.7rem; padding: 0.2rem 0.5rem' },
          token: { style: 'font-size: 0.6rem; padding: 0.1rem 0.5rem' },
          header: { style: 'font-size: 0.7rem; padding: 0.5rem 1rem' },
          item: { style: 'font-size: 0.7rem; padding: 0.45rem 1rem' },
          filterInput: { style: 'font-size: 0.7rem; padding: 0.3rem 1rem' }
        }"
      >
      </MultiSelect>
    </div>

    <!-- Modalidad -->
    <div class="col-span-12 sm:col-span-2">
      <label for="modalidad" class="block text-xs font-medium text-gray-700 mb-2">Modalidad:</label>
      <Dropdown
        id="modalidad"
        v-model="filters.modalidad"
        :options="listModalidad"
        optionLabel="name"
        optionValue="id"
        placeholder="Seleccione una opción"
        :filter="true"
        filterPlaceholder="Buscar..."
        class="w-full text-sm"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;'
          }),
          input: { style: 'font-size: 12px; padding: 0.25rem 0.5rem' },
          panel: { style: 'font-size: 12px' },
          item: { style: 'padding: 0.5rem 1rem' }
        }"
      />
    </div>

    <!-- Fecha Inicial -->
    <div class="col-span-12 sm:col-span-2">
      <label for="fechaInicial" class="block text-xs font-medium text-gray-700 mb-2">Fecha Inicial:</label>
      <InputText
        id="fechaInicial"
        type="date"
        class="w-full text-sm"
        v-model="filters.fechaInicial"
        :min="config.dateRips"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;'
          }),
        }"
      />
    </div>
    <!-- Fecha Final -->
    <div class="col-span-12 sm:col-span-2">
      <label for="fechaFinal" class="block text-xs font-medium text-gray-700 mb-2">Fecha Final:</label>
      <InputText
        id="fechaFinal"
        type="date"
        class="w-full text-sm"
        v-model="filters.fechaFinal"
        :min="config.dateRips"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;'
          }),
        }"
      />
    </div>

    <!-- Botones -->
    <div class="col-span-12 lg:col-span-12">
      <div class="flex flex-wrap justify-end items-end h-full gap-2 w-full mt-1">
        <Button
          icon="pi pi-search"
          severity="primary"
          class="p-button-xs w-full"
          style="height: 30px"
          @click="updateFilter"
          v-tippy="{ content: 'Buscar' }"
        />
        <Button
          icon="pi pi-trash"
          severity="danger"
          class="p-button-xs w-full"
          style="height: 30px"
          @click="resetClear"
          v-tippy="{ content: 'Limpiar Filtros' }"
        />
        <Button
          icon="pi pi-refresh text-sm"
          severity="success"
          class="p-button-xs w-full"
          style="height: 30px"
          @click="reload()"
          v-tippy="{ content: 'Recargar' }"
        />
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>
